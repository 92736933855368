.order_delivery_info.valid_zipcode .ship_mx_std,
.order_delivery_info .ship_mx_std_exact {
  display: none;
}

.order_delivery_info.valid_zipcode .ship_mx_std_exact {
  display: block;
}

.device-mobile .full_width .how_to_use {
  padding: 0 15px 10px;
  border-bottom: solid 1px #ccc;
}
