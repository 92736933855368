/* -----Power Reviews------ */

#pr-review-display {
  .p-w-r {
    .pr-snippet-reco-to-friend {
      .pr-reco-to-friend-message {
        font-size: 15px;
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &:before {
            content: 'Filtrado por: ';
          }
        }
        .pr-rd-review-header-sorts {
          width: 100%;
          .pr-rd-sort {
            background: url(/media/images/stars/down-arrow.png) no-repeat scroll 95% 0px transparent;
          }
        }
      }
      .pr-rd-sort-group {
        &:before {
          content: 'Ordenar por: ';
        }
      }
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 0 16px 0 0;
          margin: 5px 2px 0 0;
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'SI';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'NO';
              }
            }
          }
          .pr-modal-content {
            .pr-flag-review-btn {
              width: 100%;
              &.pr-flag-review-btn-cancel {
                margin-top: 3%;
              }
            }
          }
          .pr-rd-flag-review-container {
            clear: both;
            padding: 10px 0;
            a {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
