.elc-vto-foundation {
  .elc-vto {
    &-tab {
      font-size: 11px;
      margin-bottom: 21px;
      white-space: normal;
    }
    &-see-all-shades,
    &-see-perfect-shades {
      &.elc-button {
        [data-component] & {
          font-size: 12px;
        }
      }
    }
    &-perfect-shade-label {
      font-size: 11px;
      white-space: normal;
      width: auto;
    }
    &-product-size {
      margin-#{$ldirection}: 10px;
    }
    &-see-pair-product {
      font-size: 13px;
    }
    &-custom-shade-picker {
      width: 100%;
    }
  }
  .elc-add-to-bag-button,
  .elc-product-notify-me-button {
    font-size: 14px;
    line-height: normal;
  }
  .vto-foundation-perfect-shade {
    .slick-slide:nth-child(2) {
      flex-basis: 22%;
    }
  }
}
