.device-mobile {
  .block-template-search-mobile-v2 {
    float: left;
  }
  .site-logo-responsive-v1 {
    a img {
      margin: 0;
    }
  }
  .navigation_container ul li a {
    padding: 4px 30px 4px 15px;
  }
  .accordionItem.power-couples-row-mobile-v1 {
    h3 {
      span {
        margin-right: 10px;
      }
      span.Acné {
        background-position: -2px 4px;
        height: 40px;
      }
      span.Anti.Envejecimiento {
        background-position: 0 -74px;
        height: 40px;
      }
      span.Hidratación {
        background-position: -2px -155px;
        height: 50px;
      }
      span.Poros {
        background-position: -2px -233px;
        height: 53px;
      }
      span.Enrojecimiento {
        background-position: -2px -312px;
        height: 47px;
      }
      span.Tono.de.Piel.Disparejo {
        background-position: -2px -392px;
        height: 46px;
        margin-right: 0px;
      }
      span.three-step-icon-last {
        background-position: 0 -460px;
        float: right;
        height: 40px;
        margin-right: 0;
      }
      span.three-step-icon-last {
        background-position: 0 -505px;
      }
    }
  }
  .power-couples-formatter-rows {
    #dark-spots h3 {
      span.heading-text {
        margin-right: 0px;
      }
    }
  }
  .account-past-purchases {
    .past-purchases {
      .order-info.accordion-content {
        .products ul.product-header {
          display: none;
        }
      }
      .order.accordion-header.active {
        .view-details a span.close {
          bottom: 0px;
          top: 55px;
          width: 22px;
        }
      }
      .order-details {
        a.button.add-all-to-bag {
          height: auto;
          line-height: 20px;
          padding: 7px;
          font-size: 16px;
        }
      }
    }
  }
  .account-block-container .page_subbox_container {
    margin-right: 0px;
    a.defaultbill-address {
      input {
        font-size: 15px;
        width: 100%;
        word-wrap: break-word;
      }
    }
    .address_data {
      a.default-address {
        input.small-button {
          font-size: 15px;
          width: 100%;
          word-wrap: break-word;
          margin-bottom: 10px;
        }
      }
    }
    .address_but_wrap {
      a.edit-address {
        width: 48%;
        float: left;
        input.small-button {
          width: 100%;
        }
      }
      a.delete-address {
        width: 48%;
        float: left;
        input.small-button {
          width: 100%;
        }
      }
    }
  }
  .add_new_creditcard {
    a.add-address-link {
      margin: 0 10px 10px 10px !important;
      input.view-button {
        word-wrap: break-word;
        font-size: 15px;
        padding: 8px;
      }
    }
  }
  .page_subbox_container.column {
    margin: 0 10px 10px !important;
    .address_but_wrap {
      a input.delete-payment {
        width: 48%;
        margin-left: 0px;
      }
    }
  }
  .main_container {
    .product.spp-product .shaded {
      .spp_product_status {
        .temp_out_of_stock_msg {
          margin-bottom: 15px;
        }
      }
    }
    .mpp_product {
      display: block !important;
    }
  }
  #address_form_container {
    .ship_type_container {
      input {
        width: 30px !important;
      }
    }
    .default_ship_container input,
    .default_bill_container input {
      float: left;
      width: 20px !important;
    }
  }
  #address_form_container .page_subbox_container {
    fieldset.fs {
      div.radio input {
        width: auto !important;
      }
      div.radio.default_ship_container span {
        display: inline !important;
      }
      div.radio.default_bill_container span {
        display: inline !important;
      }
    }
  }
}

.elc-user-state-logged-in {
  .navigation_container .field-mobile-menu {
    ul.menu {
      li.parent-iniciar-sesión {
        display: none;
      }
    }
  }
}

.elc-user-state-anonymous {
  .navigation_container .field-mobile-menu {
    ul.menu {
      li.parent-salir {
        display: none;
      }
    }
  }
}

#header {
  #showsearch {
    input.searchInputs {
      width: 76%;
    }
  }
}

.sbHolder.sized {
  &.Fragrance {
    background-color: #f1f4f5;
  }
}

#top #header {
  .btn_navigation {
    background-position: 0 -9px;
    height: 52px;
  }
  .block-template-search-mobile-v2 {
    float: left;
  }
  .site-logo-responsive-v1 a {
    height: 56px;
    line-height: 56px;
    margin: 0;
    padding: 0;
  }
  .gnav-drop-down-v1 .dd-icon .link em {
    padding: 0;
    right: 3px;
    text-align: center;
    top: 11px;
  }
}

.spp_reviews {
  #BVFieldRecommendContainerID {
    #BVFieldRecommendLabelID {
      width: 100%;
      float: left;
    }
  }
  #BVFieldContextdatavalueDidYouReceiveAFreeSampleContainerID {
    #BVFieldContextdatavalueDidYouReceiveAFreeSampleLabelID {
      width: 100%;
      float: left;
    }
  }
}

@media only screen and (max-device-width: 768px) and (orientation: landscape) {
  .spp_reviews {
    #BVRRSecondarySummaryContainer {
      .BVRRSecondaryRatingSummary.BVRRSecondaryRatingSummaryNoReviews {
        padding: 0 0 55px 25px;
        #BVRRRatingSummaryNoReviewsWriteImageLinkID {
          a {
            width: 250px;
          }
        }
      }
    }
  }
}

div#main.single {
  section#need-help-panel .phone,
  section#need-help-panel .chat,
  section#need-help-panel .email {
    padding-left: 26px;
  }
  .sign-in-panel {
    #new-or-returning-radios fieldset {
      width: 100%;
    }
  }
  .form-container {
    .first-name,
    .last-name,
    .maiden-name,
    .address1,
    .address2,
    .address3,
    .address4,
    .address5,
    .state,
    .city-and-other,
    .municipality,
    .colony-and-other,
    .city,
    .city-entered,
    .colony,
    .colony-entered,
    .postal-code {
      width: 100%;
    }
  }
  #shipping-panel .select-address .sub-section select {
    width: 100% !important;
  }
}

.device-mobile #search #search-results .results .result .badge {
  .badge-inner.badge_data_1,
  .badge-inner.badge_data_30 {
    display: none;
  }
  .badge-inner.badge_data_1.new,
  .badge-inner.badge_data_30.bestseller {
    display: inline-block;
  }
}

.my-account-favorites-mobile-v1 {
  .tabs {
    .tabs-content {
      .add-to-bag {
        padding: 6px 0;
        line-height: normal;
      }
    }
  }
}

.section-international {
  .basic-content-mobile-v1 {
    .content {
      table {
        width: 100% !important;
      }
    }
  }
}

.power-couples-formatter-rows {
  .row-products-wrapper {
    .product {
      border-bottom: none;
    }
  }
}

.device-mobile {
  .contact_us {
    .form_element.registered_container {
      input.field {
        width: auto !important;
      }
    }
  }
}

#blue_ocean {
  #bo-wrapper {
    a#btnnext {
      top: 301.5px !important;
    }
  }
}

.device-mobile {
  #search {
    #search-results {
      #product-results {
        .results {
          .result {
            .badge {
              height: 36px;
              width: 36px;
              padding: 13px 0 0;
              font-size: 9px;
            }
          }
        }
      }
    }
  }
  #header {
    .btn_navigation {
      background-position: 0 -9px;
      height: 52px;
      width: 50px;
      float: left;
      position: static;
    }
  }
  .block-template-gnav-cart-v1 {
    position: static;
  }
  .block-template-site-logo-responsive-v1 {
    .site-logo-responsive-v1 {
      border-right: 1px solid #e6e6e6;
      height: 52px;
    }
  }
  .block-template-gnav-search-v1,
  .block-template-search-mobile-v1,
  .block-template-site-logo-responsive-v1,
  .block-template-gnav-cart-v1 {
    display: inline-block;
    float: left;
  }
  .customer-care-list {
    #header_chat {
      display: inline-block;
    }
  }
}

body {
  &.checkout {
    .page-footer-region {
      background-color: $cr19-bg-white;
    }
  }
  &#viewcart.checkout,
  &#index.checkout.elc-user-state-logged-in {
    .sticky-checkout-button {
      a.btn.btn-primary {
        margin: 0;
      }
    }
    #main.single #payment-panel {
      a.btn.btn-primary {
        margin: 0;
      }
    }
    .page-footer-region {
      .page-footer {
        .sitewide-footer-formatter {
          &__bottom {
            padding-top: 0;
            &-wrapper {
              .sitewide-footer-formatter__copyright {
                margin: 22px 15px 35px;
              }
            }
          }
        }
      }
    }
  }
  &#confirm {
    background-color: $cr19-bg-white;
  }
  #foreground-node {
    .close-container {
      &.close-x-button {
        position: inherit;
      }
    }
  }
}

#main {
  .new-account {
    @if $adpl_forms_styles == true {
      input[type='text'] + label {
        line-height: get-line-height(20px, 42px);
      }
    }
  }
  .return-user {
    @if $adpl_forms_styles == true {
      input[type='text'] + label {
        line-height: get-line-height(20px, 62px);
      }
    }
  }
}
